<template>
  <section id="UnderMaintenance" class="centralize">
    <div class="container-msg centralize">
      <img v-if="$mq == 'lg'" src="../assets/logotipo_green.png" alt="Escala10" width="300">
      <img v-else src="../assets/logotipo_green.png" alt="Escala10" width="200">
      <br><br>
      <h1>Fala Cartoleiro!</h1>
      <p>Queremos ver você mitar com o nosso site, e para que isso aconteça estamos aplicando alguns ajustes para melhorar ainda mais a sua experiência!
      <br />Pedimos desculpas, mas o E10 está em manutenção e retornará em breve.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UnderMaintenance',
  data() {
    return {

    }
  }
}
</script>

<style scoped>
@media (min-width: 342px) and (max-width: 1024px) {
  #UnderMaintenance {
    font-size: 14px !important;
  }
  .container-msg {
    width: 85% !important;
  }
}
  #UnderMaintenance {
    height: 100%;
    width: 100%;
    background-image: url('../assets/bg_escala10.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 18px;
  }
  h1 {
    font-size: 2.5em;
    color: white;
    font-weight: bolder;
    margin: 0;
  }
  p {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 1.2em;
  }
  .container-msg {
    max-width: 70%;
    padding: 15px;
  }
</style>
